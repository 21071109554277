import { Fragment } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

import React, { useState, useEffect, useRef, memo} from 'react';
import Auth from '../utils/auth'; // Import Auth
import SelectedPosts from './SelectedPosts';
import SearchArea from './SearchArea';
import Post from './Post';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

//import { useState } from "react";
import '../App.css';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const Footer = (props) => {
    
}


const HeaderOptions = (props) => {
    if (Auth.loggedIn()) {
        return (
            <>
                <a
                    onClick={() => { props.getLocation() }}
                    className="text-sm font-semibold leading-7 text-gray-900"
                >
                Go to Me
                </a>
                <a
                    onClick={() => { props.setIsPostPaneOpen(true)}}
                    className="text-sm font-semibold leading-7 text-gray-900"
                >
                Protest
                </a>
                <a
                    onClick={() => { props.setIsVisiblePaneOpen(true)}}
                    className="text-sm font-semibold leading-7 text-gray-900">
                    
                Visible
                </a>
                <a
                    onClick={() => { props.setIsAccountPaneOpen(true); }}
                    className="text-sm font-semibold leading-7 text-gray-900"
                >
                Account
                </a>
                <a
                    onClick={() => {Auth.logout()}}
                    className="text-sm font-semibold leading-7 text-gray-900"
                >
                Log Out
                </a>
            </>
        )
    } else {
        return (
            <>
                <a
                    onClick={() => { props.getLocation() }}
                    className="text-sm font-semibold leading-7 text-gray-900"
                >
                Go to Me
                </a>
                <a
                    onClick={() => { props.setIsVisiblePaneOpen(true)}}
                    className="text-sm font-semibold leading-7 text-gray-900">
                    
                Visible
                </a>
                <a
                    onClick={() => { props.setIsRegisterPaneOpen(true)}}
                    className="text-sm font-semibold leading-7 text-gray-900"
                >
                Register
                </a>
                <a
                    onClick={() => { props.setIsLoginPaneOpen(true)}}
                    className="text-sm font-semibold leading-7 text-gray-900"
                >
                Log In
                </a>
            </>
        )
    }
}

const HeaderOptionsCollapsed = (props) => {
    if (Auth.loggedIn()) {
        return (
            <>
                <a
                    onClick={() => { props.getLocation(); props.setMobileMenuOpen(false) }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                Go to Me
                </a>
                <a
                    onClick={() => { props.setMobileMenuOpen(false); props.setIsPostPaneOpen(true); }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                Protest
                </a>
                <a
                    onClick={() => { props.setMobileMenuOpen(false); props.setIsVisiblePaneOpen(true); }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                Visible
                </a>
                <a
                    onClick={() => { props.setMobileMenuOpen(false); props.setIsAccountPaneOpen(true); }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                Account
                </a>
                <a
                    onClick={() => { props.setMobileMenuOpen(false); Auth.logout(); }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                Log Out
                </a>
            </>
        )
    } else {
        return (
            <>
                <a
                        onClick={() => { props.getLocation(); props.setMobileMenuOpen(false) }}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                    Go to Me
                </a>
                 <a
                    onClick={() => { props.setMobileMenuOpen(false); props.setIsVisiblePaneOpen(true); }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                Visible
                </a>
                <a
                    onClick={() => { props.setMobileMenuOpen(false); props.setIsAccountPaneOpen(true); }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                Account
                </a>
                <a
                    onClick={() => { props.setMobileMenuOpen(false); props.setIsRegisterPaneOpen(true); }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                Register
                </a>
                <a
                    onClick={() => { props.setMobileMenuOpen(false); props.setIsLoginPaneOpen(true); }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                Log In
                </a>
            </>
        )
    }
}

const Header = (props) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const expand = 'lg';
    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
            
        } else {
            props.setCurrentLatitude(null);
            props.setCurrentLongitude(null);
            alert("There was an error geolocating you.");
        }
    }
    
    function showPosition(position) {
        const getZoomLevel = () => {
            if (props.viewport.zoom > 12.5) { 
                return props.viewport.zoom;
            } else {
                return 12.5;
            }
        }
        props.setViewport({latitude: position.coords.latitude, longitude: position.coords.longitude, zoom: getZoomLevel()});
        props.setCenterLatitude(position.coords.latitude);
        props.setCenterLongitude(position.coords.longitude);
        props.setCurrentLatitude(position.coords.latitude);
        props.setCurrentLongitude(position.coords.longitude);
        // console.log("Latitude: " + position.coords.latitude + 
        // "\nLongitude: " + position.coords.longitude);
    }
    
    function showError(error) {
        props.setCurrentLatitude(null);
        props.setCurrentLongitude(null);
        switch(error.code) {
            case error.PERMISSION_DENIED:
                props.setGpsSnackbarOpen(true);
                console.log("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                console.log("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                console.log("An unknown error occurred.");
                break;
        }
    }
    return (
        <header className="header bg-transparent ">
            <nav className="header mx-auto flex max-w-7xl items-center justify-between p-1 lg:px-8" aria-label="Global">
                <div className="projectCivilian-header flex lg:flex-1" onClick={() => props.setIsOnboardOpen(true)}>
                    <a className="">
                        <img className="h-8 mr-1.5 w-auto projectCivilian-logo" src="projectCivilian.svg" alt="" />
                    </a>
                    <div className="text-md mt-3">projectCivilian</div>
                </div>
                <div className="lg:hidden">
                <button
                    type="button"
                    className="btn-active"
                    style={{verticalAlign: 'top', 'marginRight': '10px'}}
                    onClick={() => { setMobileMenuOpen(false); props.setIsPostPaneOpen(true); }}
                >Protest</button>
                <button
                    type="button"
                    className="btn-active"
                    style={{verticalAlign: 'top'}}
                    onClick={() => getLocation()}
                >Go to Me</button>
                <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(true)}
                >
                    <span className="sr-only">Open Main Menu</span>
                    
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                </div>
                <Popover.Group className="hidden lg:flex lg:gap-x-12">
                {/* <Popover className="relative">
                    <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-7 text-gray-900">
                    Search
                    <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </Popover.Button>

                    <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                    >
                    <Popover.Panel className="absolute top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                        <div className="p-1 items-center justify-center">
                            <div className="flex flex-none justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                <div
                                    key={props.search}
                                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm hover:bg-gray-50"
                                >
                                    <SearchArea
                                        filterData={props.filterData}
                                        filteredData={props.filteredData}
                                        setFilteredData={props.setFilteredData}
                                    />
                                </div>
                            </div>
                        {/* {products.map((item) => (
                            <div
                            key={item.name}
                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-7 hover:bg-gray-50"
                            >
                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                            </div>
                            <div className="flex-auto">
                                <a href={item.href} className="block font-semibold text-gray-900">
                                {item.name}
                                <span className="absolute inset-0" />
                                </a>
                                <p className="mt-1 text-gray-600">{item.description}</p>
                            </div>
                            </div>
                        ))}
                        </div>
                        <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                        {callsToAction.map((item) => (
                            <a
                            key={item.name}
                            href={item.href}
                            className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                            >
                            <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                            {item.name}
                            </a>
                        ))} 
                        </div>
                    </Popover.Panel>
                    </Transition>
                </Popover> */}
                {/* <SearchArea
                    filterData={props.filterData}
                    filteredData={props.filteredData}
                    setFilteredData={props.setFilteredData}
                /> */}
               
                <HeaderOptions
                    getLocation={getLocation}
                    setIsVisiblePaneOpen={props.setIsVisiblePaneOpen}
                    setIsRegisterPaneOpen={props.setIsRegisterPaneOpen}
                    setIsLoginPaneOpen={props.setIsLoginPaneOpen}
                    setIsAccountPaneOpen={props.setIsAccountPaneOpen}
                    setIsPostPaneOpen={props.setIsPostPaneOpen}
                ></HeaderOptions>
                </Popover.Group>
                
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                    <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">projectCivilian</span>
                    <img
                        className="h-8 w-auto"
                        src="projectCivilian.svg"
                        alt=""
                    />
                    </a>
                    <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                    >
                    <span className="sr-only">Close Menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                        <Disclosure as="div" className="-mx-3">
                        {({ open }) => (
                            <>
                            <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                Search
                                <ChevronDownIcon
                                className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                aria-hidden="true"
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="mt-2 space-y-2">
                                <SearchArea
                                    theme={props.theme}
                                    searchMade={props.searchMade}
                                    setSearchMade={props.setSearchMade}
                                    setSearch={props.setSearch}
                                    searchText={props.searchText}
                                    setSearchText={props.setSearchText}
                                    search={props.search}
                                    postLength={props.postLength}
                                    filterData={props.filterData}
                                    filteredData={props.filteredData}
                                    setFilteredData={props.setFilteredData}
                                    setMobileMenuOpen={setMobileMenuOpen}
                                />
                                {/* {[...products, ...callsToAction].map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {item.name}
                                </Disclosure.Button>
                                ))} */}
                            </Disclosure.Panel>
                            </>
                        )}
                        </Disclosure>
                        
                       <HeaderOptionsCollapsed
                            setMobileMenuOpen={setMobileMenuOpen}
                            getLocation={getLocation}
                            setIsVisiblePaneOpen={props.setIsVisiblePaneOpen}
                            setIsRegisterPaneOpen={props.setIsRegisterPaneOpen}
                            setIsAccountPaneOpen={props.setIsAccountPaneOpen}
                            setIsLoginPaneOpen={props.setIsLoginPaneOpen}
                            setIsPostPaneOpen={props.setIsPostPaneOpen}
                       />
                    </div>
                    </div>
                </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
};
  
export default Header; 