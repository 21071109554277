import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_IDME } from '../utils/mutations';

function IdmeCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  // Move useMutation to the top level of the component.
  const [updateUserIdme, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_USER_IDME);

  // This function sends the id.me code along with your JWT to your backend.
  const fetchIdmeData = async (code) => {
    const token = localStorage.getItem('id_token');
    if (!token) {
      setErrorMessage('No JWT found. Please log in again.');
      return;
    }
    try {
      const response = await fetch(`/idme?code=${code}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        setErrorMessage(`Error from backend: ${errorText}`);
        return;
      }
      
      const data = await response.json();
      console.log('Data from /idme:', data);
      
      // Build the id.me data from the response (adjust according to your actual response structure)
      const idmeData = {
        uuid: data.idmeUser.attributes[4].value,
        status: data.idmeUser.status,
      };
      
      console.log(idmeData);
      // Use the mutation to update the user
      let updateRequest = await updateUserIdme({ variables: { idmeData } });
      console.log(updateRequest);
      navigate('/'); // Redirect after successful update
    } catch (err) {
      console.log(mutationError);
      console.error('Error fetching /idme data:', err);
      setErrorMessage('Network error occurred.');
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    if (code) {
      fetchIdmeData(code);
    } else {
      setErrorMessage('No code parameter found in URL.');
    }
  }, [location, navigate]);

  return (
    <div>
      {errorMessage ? (
        <p>Error: {errorMessage}</p>
      ) : (
        <p>Processing your login. Please wait...</p>
      )}
    </div>
  );
}

export default IdmeCallback;
