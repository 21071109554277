import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { SIGNUP } from '../utils/mutations';
import Auth from '../utils/auth';
import { quantum } from 'ldrs'
import '../App.css';

quantum.register()

const Onboard = (props) => {
  const [loading, setLoading] = useState(false);

  function generateRandomBase64String(length) {
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  
  // Generate a random base 64 32 character string
  var randomBase64StringUser = generateRandomBase64String(16);
  var randomBase64StringPassword = generateRandomBase64String(16);

  const [formState, setFormState] = useState({
    username: randomBase64StringUser,
    password: randomBase64StringPassword,
  });
  const [signUp, { error, data }] = useMutation(SIGNUP);

  // update state based on form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // submit form
  const handleFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    console.log(formState)
    console.log(event);
    try {
      const { data } = await signUp({
        variables: { ...formState },
      });
      console.log(data);
      console.log(formState);
      if (data.signUp.token !== null) {
        await Auth.login(data.signUp.token);
        props.setIsRegisterPaneOpen(false);
      }
    
    } catch (e) {
      console.log(e);
    }
  };


const DynamicText = () => {
  const messages = [
"Corrupt government practices.",
  "Waste dumping into waterways.",
  "Neglected potholes and poor road maintenance.",
  "Unjust legal challenges and discriminatory laws.",
  "Environmental degradation and deforestation.",
  "Police brutality and excessive use of force.",
  "Income inequality and wealth disparity.",
  "Government inaction on climate change.",
  "Cuts to public education funding.",
  "Lack of affordable healthcare.",
  "Discriminatory practices in housing and employment.",
  "Political censorship and restrictions on free speech.",
  "Corporate greed and monopolistic practices.",
  "Gun violence and lax gun control laws.",
  "Racial injustice and systemic racism.",
  "Voter suppression and electoral fraud.",
  "Unethical surveillance and invasion of privacy.",
  "Indigenous rights violations and land theft.",
  "Labor exploitation and unsafe working conditions.",
  "Mismanagement of natural resources.",
  "Urban decay and lack of public services.",
  "Religious intolerance and hate crimes.",
  "Inadequate disaster response and planning.",
  "Gender discrimination and unequal pay.",
  "Erosion of civil liberties and human rights."
];

  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const [opacity, setOpacity] = useState(1); // Control opacity for fade effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessage((prevMessage) => {
        // Random index for the next message
        const randomIndex = Math.floor(Math.random() * messages.length);
        const nextIndex = (messages.indexOf(prevMessage) + 1) % messages.length;
        return messages[randomIndex];
      });
    }, 5000); // Update every 5 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="text-xl dynamic-text font-bold text-transparent b
    -gradient-to-r from-purple-500 to-gray-500">
      {currentMessage}
    </div>
  );
};

  // Print the random base 64 32 character string to the console
  return (
    <main style={{zIndex: '5'}} className="m-5 margin-auto flex flex-col">
      <div className="flex flex-col">
        <div className="flex flex-col">
        <img className="h-20 w-auto" src="projectCivilian.svg" alt="" />
        <div className="flex flex-col text-center mx-auto">
        <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      {/* Hero Section */}
      <section className="hero text-black py-20">
              <div className="container mx-auto px-4 text-center">
                <h1 className="text-3xl md:text-4xl font-bold mb-4 welcome">Welcome to projectCivilian.org</h1>
                <p className="text-lg md:text-xl mb-8">Connecting you directly with your government officials and using AI to solve local problems.</p>
                <a
                  onClick={() => {
                    // Scroll to the about section.
                    document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
                  }}
                  href="#about"
                  id="hero"
                  className="bg-white text-blue-600 hover:bg-grey-100 px-6 py-3 rounded-full font-semibold"
                >
                  Explore More
                </a>
              </div>
              </section>

              {/* About Section */}
              <section id="about" className="py-16 bg-grey-900">
                <div className="container mx-auto px-4">
                  <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 text-grey-800">What You Can Do</h2>
                  <p className="text-lg md:text-xl text-gray-600 text-center mb-8">
                    ProjectCivilian.org aims to empower citizens by providing a direct line to their government officials. 
                    We use AI to identify, analyze, and provide solutions to specific problems, 
                    address grievances, and express dissatisfaction in a constructive manner. Whether it's local issues or 
                    broader concerns, our platform ensures your voice is heard and acted upon effectively.
                  </p>
                </div>
                <div>              
                  <a
                    onClick={() => {props.setIsOnboardOpen(false); props.setIntroSnackbarOpen(true);}}
                    href="#explore"
                    className="bg-white text-blue-600 hover:bg-grey-100 px-6 py-3 rounded-full font-semibold"
                  >I want to see it first.</a>
                   <br/>
                   <br/>
                <a
                    onClick={() => {props.setIsRegisterPaneOpen(true); props.setIsOnboardOpen(false); props.setIntroSnackbarOpen(true);}}
                    href="#about"
                    className="bg-white text-purple-600 hover:bg-grey-100 px-6 py-3 rounded-full font-semibold clear-both"
                  >I'm sold, sign me up!</a>
                </div>
              </section>

              {/* Use Case Section */}
              <section className="use-case py-16 bg-gray-200">
                <div className="container mx-auto px-4">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 text-grey-800">What's it Used For?'</h2>
                  <div className="text-gray-700 text-center mb-8">
                    <DynamicText></DynamicText>
                    <p className="mt-4">Using ProjectCivilian.org, we can easily connect with local officials to report the issue. Our AI tool analyzes the problem, suggests potential solutions, and helps facilitate a constructive dialogue between citizens and officials to resolve the matter efficiently.
                    </p>
                  </div>
                  <a
                    onClick={() => {props.setIsOnboardOpen(false); props.setIntroSnackbarOpen(true);}}
                    href="#explore"
                    className="bg-white text-blue-600 hover:bg-grey-100 px-6 py-3 rounded-full font-semibold"
                  >I want to see it first.</a>
                   <br/>
                   <br/>
                <a
                    onClick={() => {props.setIsRegisterPaneOpen(true); props.setIsOnboardOpen(false);}}
                    href="#about"
                    className="bg-white text-purple-600 hover:bg-grey-100 px-6 py-3 rounded-full font-semibold clear-both"
                  >I'm sold, sign me up!</a>

                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Onboard;
