import React, { useEffect, useState } from 'react';
import IDme from './IDme';

function Account() {
  const [showIdmeButton, setShowIdmeButton] = useState(true);

  useEffect(() => {
    setShowIdmeButton(true);

  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <main className="p-5 m-5 text-center justify-contents-center flex flex-col">
      <div className="flex flex-col">
        <div className="flex flex-col">
          <span
            className="text-center text-xl"
            style={{ fontSize: '32px', margin: '20px' }}
          >
            Account
          </span>
          <p className="text-sm">Connect your account with ID.me.</p>
          <p className="text-sm">By connecting your profile, you will recieve a badge on your posts if you are a teacher, a military veteran or on active duty, a nurse, or first responder. While your name and location are visible through verification, we do not use any information except for a unique identifier associated with your account.</p>
          <br/><p className="text-sm">We will never store names or locations from ID.me.</p>
          <div>
          <a href="https://groups.id.me/?client_id=9c5df2988ffbe63c12afdd0535023cf2&redirect_uri=https://www.projectcivilian.org/idme-callback&response_type=code&scopes=government,military,nurse,responder,teacher">
            <center>
              <img
                style={{ cursor: 'pointer', width: '200px' }}
                src={
                "https://s3.amazonaws.com/idme/buttons/v4/verify-with-idme-green.png"
                }
                alt="Verify with ID.me"
              />
            </center>
          </a> 
          <p className="text-sm">
          ID.me is a trusted technology partner in helping to keep your
          personal information safe. We specialize in digital identity
          protection and help partners make sure you're you—and not someone
          pretending to be you—before giving you access to your information or
          offer.
          </p>
          <br/>
          <a className="text-xs" href="https://www.id.me/about" target="_blank" rel="noreferrer">
            Learn more about ID.me.
          </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Account;