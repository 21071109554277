
function scaleValuesToMaxCount(values, maxCount) {
  // Find the maximum value in the original array
  const originalMax = Math.max(...values);
  
  // Scale each value in the array to the new maxCount, maintaining their relative proportions
  const scaledValues = values.map(value => (value / originalMax) * maxCount);
  
  return scaledValues;
}

export const currentLocationLayer = {
  id: 'current-location-point',
  type: 'circle',
  minzoom: 10,
  paint: {
    'circle-radius': 10,
    'circle-color': 'rgba(0, 0, 0, .95)',
    'circle-stroke-width': 2,
    'circle-stroke-color': 'rgba(255, 255, 255, 0.95)'
  }
};
export const currentLocationLabel = {
  id: 'current-location-label',
  type: 'symbol',
  minzoom: 10,
  layout: {
    'text-field': 'You',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],

    // 'text-anchor': 'left',
    // 'text-offset': [0.5, 0], // Adjust the offset as needed
    'text-size': 14,
    'text-anchor': 'center',
    'text-allow-overlap': true // This prevents Mapbox from hiding overlapping labels
  },
  paint: {
    'text-color': 'rgba(255, 255, 255, 0.95)',
    'text-halo-color': 'rgba(0, 0, 0, .95)',
    'text-halo-width': 3
  }
};

export const clusterLayer = (maxCount) => {
  // Ensure maxCount is greater than 1
  const safeMaxCount = Math.max(20, maxCount);

  return {
    id: 'clusters',
    type: 'circle',
    source: 'postPoints',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'interpolate', ['exponential', 1], ['get', 'point_count'],
        1, 'rgba(0, 0, 0, .5)',
        safeMaxCount, 'rgba(150, 150, 150, 1)',
      ],
      'circle-radius': [
        'interpolate', ['exponential', 1], ['get', 'point_count'],
        1, 25,
        safeMaxCount, 50
      ],
      // Ensure all other properties follow the same pattern...
    },
  };
};
export const clusterCountLayer = (maxCount) => {
  const safeMaxCount = Math.max(20, maxCount);
  return {
  id: 'cluster-count',
  type: 'symbol',
  source: 'postPoints',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': [
      'interpolate', ['exponential', 1], ['get', 'point_count'],
      1, 25,
      safeMaxCount, 50
    ],
    'text-allow-overlap': true // This prevents Mapbox from hiding overlapping labels
  },
  paint: {
    'text-color': '#ffffff',
  },
  }
};

export const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'postPoints',
  
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-radius': 10,
    'circle-color': '#462b65',
    'circle-stroke-width': 5,
    'circle-stroke-color': 'rgba(0, 0, 0, .15)',
  }
  // layout: {
  //   'icon-image': 'leader_line',
  //   'icon-anchor': 'bottom',
  //   'text-offset': [0, -10.5],
  // }
};

export const pointLayer = {
  id: 'projectCivilian',
  type: 'circle',
  paint: {
    'circle-color': 'rgba(182, 0, 0, 0.75)',
    'circle-radius': 10,
    'circle-stroke-width': 20,
    'circle-stroke-color': 'rgba(154, 154, 154, 0.09)',
  },
};

export const pointLayerLabel = {
  id: 'current-location-label',
  type: 'symbol',
  source: 'projectCivilian', // Make sure this source points to the current location data
  layout: {
    'text-field': 'Protest',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],

    // 'text-anchor': 'left',
    // 'text-offset': [0.5, 0], // Adjust the offset as needed
    'text-size': 14,
    'text-anchor': 'center',
    'text-allow-overlap': true // This prevents Mapbox from hiding overlapping labels

  },
  paint: {
    'text-color': '#000',
    'text-halo-color': '#ffffff',
    'text-halo-width': 1
  }
};

