import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { GET_ME } from '../utils/mutations';

const UserBadge = (props) => {
    const { user: propUser, idme: propIdme } = props;

    // Call the query but skip if both user and idme are provided via props.
    const { loading, error, data } = useQuery(GET_ME, {
      skip: !!(propUser && propIdme)
    });
    console.log(data);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading user data: {error.message}</p>;

    // Use props if available; otherwise, fall back to query data.
    const idme = propIdme || (data?.me && data.me.idme);
    const user = propUser || (data?.me && data.me.username);

    const isVerified = idme && idme.status && idme.status.some(s => s.verified);

    return (
        <div className="m-2 badge-container">
        {isVerified ? (
          <>
          <div className="align-middle">
            <span className="m-2 text-xs align-middle">by {user}</span><br/>
            {idme?.status?.length > 0 &&
                idme.status.map((status, index) => {
                    let useEmoji = '';
                    switch (status.group) {
                    case "military":
                        useEmoji = '🎖️';
                        break;
                    case "teacher":
                        useEmoji = '👩‍🏫';
                        break;
                    default:
                        useEmoji = '❓';
                    }
                    return (
                    <div
                        key={index}
                        className={`group-badge align-middle ${status.group.toLowerCase().replace(/\s+/g, '-')}`}
                    >
                        <div className="badge-text align-middle">
                        {useEmoji} {status.group}
                        </div>
                    </div>
                    );
                })
            }
           </div>       
          </>
        ) : (
            <span className="m-2 text-xs align-middle">by {user}</span>
        )}
      </div>
    );
  };
  
  export default UserBadge;