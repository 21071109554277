import React, { Component, useEffect, useState } from "react";
import { useMutation, useQuery } from '@apollo/client';
import * as toxicity from '@tensorflow-models/toxicity';
import ToxicityGrid from './ToxicityGrid'; 
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import '../App.css';
import { ADD_POST, UPDATE_POST } from '../utils/mutations';
import Auth from '../utils/auth';
import { GET_ALL_POSTS } from '../utils/queries';
import UserBadge from "./UserBadge";

import postToGeoJSON from '../utils/postToGeoJSON';
// import { toxicity } from '@tensorflow-models/toxicity';
  // Post states.

// Toxicity filter based on https://medium.com/tensorflow/text-classification-using-tensorflow-js-an-example-of-detecting-offensive-language-in-browser-e2b94e3565ce
const Post = (props) => {

  // This is used for the state of the form
  let bodyValue;
  if (props.editPostBody) {
    bodyValue = props.editPostBody;
  } else {
    bodyValue = '';
  }
  const [formState, setFormState] = useState({ body: bodyValue });
  const [addPost, { error: addPostError }] = useMutation(ADD_POST);
  const [updatePost, { error: updatePostError }] = useMutation(UPDATE_POST);


  const [postText, setPostText] = useState(bodyValue);
  const [postValidationText, setPostValidationText] = useState();
  const [toxicityResult, setToxicityResult] = useState([]);
  const [spinnerHidden, setSpinnerHidden] = useState(true);
  const [postDisabled, setPostDisabled] = useState(false);
  const [postButtonText, setPostButtonText] = useState("Post");
  const [didPostSend, setDidPostSend] = useState(0);
  const [approvePost, setApprovePost] = useState(true);
  const [closeEnough, setCloseEnough] = useState(false);
  const [currentDistance, setCurrentDistance] = useState(0);
  const { loading, error, data, refetch } = useQuery(GET_ALL_POSTS);
  const [nearby, setNearby] = useState(false);
  // This should probably be server side.
  const categories = require('../utils/categories.json');

  const checkPost = async (posts) => {
    if (postText.length !== 0) {

        // The minimum prediction confidence.
        const threshold = 0.2;

        // Which toxicity labels to return.
        const labelsToInclude = ['toxicity', 'severe_toxicity', 'identity_attack', 'insult', 'threat', 'sexual_explicit', 'obscene'];

        
        // setPostButtonText(" Checking post content...");

        const model = await toxicity.load(threshold, labelsToInclude);
        const predictions = await model.classify([postText]);
        // console.log(predictions);
        //predictions.forEach((prediction) => {
          // console.log(`${prediction.label}: ${prediction.results[0].match}, ${prediction.results[0].probabilities[1]}`);
        //});

        let isPostApproved = true;
        for (let prediction of predictions) {
            if (prediction.results[0].match === true) {
                isPostApproved = false;
                break;
            }
        }

        setApprovePost(isPostApproved);
        setPostButtonText("Post");

        if (isPostApproved) {
            console.log("Passed toxicity filter.");
        } else {
            console.log("Failed toxicity filter.");
        }

        return isPostApproved;
    }
    return false; // Default return in case postText.length is 0
}
useEffect(() => {
  const checkDistanceInKilometers = (centerLatitude, centerLongitude, currentLatitude, currentLongitude) => {
    return Math.acos(Math.sin(centerLatitude)*Math.sin(currentLatitude)+Math.cos(centerLatitude)*Math.cos(currentLatitude)*Math.cos(currentLongitude-centerLongitude))*63710
  }
  if (props.currentLatitude && props.currentLongitude) {
    let distance = checkDistanceInKilometers(props.centerLatitude, props.centerLongitude, props.currentLatitude, props.currentLongitude);
    setCurrentDistance(distance);
    // console.log(distance);
    if (distance <= 100) {
      setCloseEnough(true);
    } else {
      setCloseEnough(false);
    }
  }
}, [props.currentLatitude, props.currentLongitude, props.centerLatitude, props.centerLongitude]);
 
  
  // update state based on form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  useEffect(() => {
    handleChange({ target: { name: "body", value: postText } });
  }, [postText]);

  const handleFormSubmit = async (event) => {
    if (postText.length !== 0) {
      event.preventDefault();
      try {
        setSpinnerHidden(false);
        setPostButtonText("Checking post content...");
        // let approvePost = await checkPost();
        // console.log(approvePost);
        // if (approvePost === true) {
        if (true) {
          let mutationResponse;
          if (props.update === true) {
            mutationResponse = updatePost({
              variables: {userId: props.editUser, postId: props.editPostId, body: formState.body, nearby},
            });
          } else {
            mutationResponse = addPost({
              variables: {body: formState.body, latitude: props.centerLatitude, longitude: props.centerLongitude, nearby},
            });
            props.setPostSnackbarOpen(true);
            props.setIncomingPost(formState.body)
          }
          // window.location.reload();
          // console.log(mutationResponse);
        }
        // set to change
        setPostDisabled(false);
        setSpinnerHidden(true);
        setPostButtonText("Posted!");
        props.setIsPostPaneOpen(false);
      } catch (e) {
        console.log(e);
      }
    } else {
      event.preventDefault();
      setPostValidationText("Post cannot be empty.");
    } 
  };

    return ( 
        <div className="py-5 align-items-center justify-content-center text-center">
          {props.editPostId}
          <div className="flex justify-center text-xl m-4">Protest</div>
          {/* <div className="flex justify-center text-l m-4">Choose from the following examples or write your own:</div> */}
          {/* <select className="p-2 m-2" onChange={(e) => {
              setPostText(e.target.value);
              document.querySelector('.post-textarea').value = e.target.value;
            }}>
              <option value="">Choose an example</option>
              {categories.map((category, index) => {
                return <option key={index} value={category}>{category}</option>
              }
              )}
          </select> */}
          <form onSubmit={handleFormSubmit}>
            <UserBadge></UserBadge>
            <textarea
              className="m-2 p-2 post-textarea"
              value={postText}
              placeholder="What are you protesting?"
              name="body"
              maxLength={128}
              onChange={(e) => {
                setPostText(e.target.value);
                handleChange(e);
              }}
              rows="6"
              columns="128"
            />
            <br/>
            <div>
            <p>{128 - postText.length} letters left.</p>

            <p className="fs-7 m-2">Post @ {`${props.centerLatitude.toFixed(5)}, ${props.centerLongitude.toFixed(5)}`}
            {props.currentLatitude && props.currentLongitude && closeEnough &&
                <div className="fs-7">
                <p>
                  <input id="nearby" className="checkbox" type="checkbox" onChange={() => {
                    setNearby(!nearby);
                  }}/> <span className="material-symbols-outlined">
                  how_to_reg
                  </span>{currentDistance.toFixed(0)} meters from point; show nearby badge? 
                </p>
                Current @ {`${props.currentLatitude}, ${props.currentLongitude}`}
                </div>
            }
            </p>
            </div>
            <span className="text-red-800">{postValidationText}</span>
            <div>
              <Button className="btn-active" type="submit" variant="flat" disabled={postDisabled}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="false"
                hidden={spinnerHidden}
              />{postButtonText}
              </Button>
            </div>
          </form>
          <br/>
          </div>
    )
}

export default Post;