const postToGeoJSON = (posts) => {
    let geoJSON = {
        "type": "FeatureCollection",
        "metadata": {
        "title": "Main Posts",
        },
        "features": []
    };
    posts.forEach(function (post) {
        // console.log(post);
        geoJSON.features.push({
            "type": "Feature", 
            "properties": {
                "id": post._id,
                "user": post.user.username,
                "userId": post.user._id,
                "idme": post.user.idme,
                "cluster": false,
                "body": post.body,
                "solutions": post.solutions,
                "issues": post.issues,
                "civicInfo": JSON.parse(post.civicInfo) || null,
                "nearby": post.nearby || false,
                "time": post.createdAt,
                
            },
            "geometry" : {
                "type": "Point",
                "coordinates": [
                    post.longitude,
                    post.latitude
                ]
            }
        })
    });
    return geoJSON;
}

export default postToGeoJSON;
