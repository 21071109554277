import * as React from 'react';
import {useState, useRef, useEffect, useCallback, memo} from 'react';
import {Map, Marker, Source, Layer} from 'react-map-gl';
import Pin from './Pin';
import {clusterLayer, currentLocationLabel, clusterCountLayer, unclusteredPointLayer, pointLayer, pointLayerLabel, currentLocationLayer} from '../utils/layers';
import '../App.css';
import Auth from '../utils/auth'
import PuffLoader from 'react-spinners/PuffLoader';

// TODO: Move to .env file.
const MAPBOX_TOKEN = 'pk.eyJ1IjoicHJvamVjdGNpdmlsaWFuIiwiYSI6ImNsbDduZWdzcDBzcGUzanNzcjdxamVicXMifQ.lVmATzvMkyZSxPthIay_mA'; // Set your mapbox token here

const PostMap = (props) => {
  const mapRef = useRef();
  const bounds = mapRef.current
  ? mapRef.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
  : null;

  const map = mapRef.current?.getMap();
  const [pointData, setPointData] = useState(null);
  const [currentLocationData, setCurrentLocationData] = useState(null);
  const [maxClusterSize, setMaxClusterSize] = useState(0); // Add this line
  const [pointsLoaded, setPointsLoaded] = useState(false); // New state for tracking data load
  const updateMaxClusterSize = () => {
    const maxClusterSize = 25;
    if (!map) return;

    const clusters = map.querySourceFeatures('postPoints', {
      filter: ['has', 'point_count']
    });

    const maxCount = clusters.reduce(
      (max, cluster) => Math.max(max, cluster.properties.point_count),
      0
    );
    setMaxClusterSize(Math.max(maxClusterSize, maxCount));
    setPointsLoaded(true);
  };

  
  function getCenter({center, angle, radius}) {
    return {
      type: 'Point',
      coordinates: [center[0], center[1]]
    };
  }

  // useEffect(() => {
  //   updateMaxClusterSize();
  // }, [props.postGeoJSON]);
  
  useEffect(() => {
    const animation = window.requestAnimationFrame(() =>
      setPointData(getCenter({center: [props.viewport.longitude, props.viewport.latitude]}))
    );
    return () => window.cancelAnimationFrame(animation);
  }, [props.viewport]);

  useEffect(() => {
    setCurrentLocationData({
      type: 'Point',
      coordinates: [props.currentLongitude, props.currentLatitude]
    })
  }, [props.currentLatitude, props.currentLongitude]);
  useEffect(() => {
    if (!mapRef.current) return;
    const map = mapRef.current.getMap();
  
    const handleClusterClick = (e) => {
      const clusterId = e.features[0].properties.cluster_id;
      const pointCount = e.features[0].properties.point_count;
      const clusterSource = mapRef.current.getSource('postPoints');
      clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
        props.setSelectedMapPosts(features);
        props.setIsSelectedPaneOpen(true);
      });
    };
  
    const handleUnclusteredPointClick = (e) => {
      const feature = e.features.splice(0, 1);
      if (feature) {
        props.setSelectedMapPosts(feature);
        props.setIsSelectedPaneOpen(true);
      }
    };
  
    const handleProjectCivilianClick = (e) => {
      if (Auth.loggedIn()) {
        props.setIsEditMode(false);
        props.setIsPostPaneOpen(true);
      } else {
        props.setIsRegisterPaneOpen(true);
      }
    };
  
    // Register event listeners once when the map loads.
    map.on('click', 'clusters', handleClusterClick);
    map.on('click', 'unclustered-point', handleUnclusteredPointClick);
    map.on('click', 'projectCivilian', handleProjectCivilianClick);
  
    // Cleanup event listeners on unmount.
    return () => {
      map.off('click', 'clusters', handleClusterClick);
      map.off('click', 'unclustered-point', handleUnclusteredPointClick);
      map.off('click', 'projectCivilian', handleProjectCivilianClick);
    };
  }, [props]); // Include only stable dependencies.

  return (
    <>
      <Map
        {...props.viewport}
        mapStyle="mapbox://styles/projectcivilian/cluf58pb1001x01r79z85fuh1"
        mapboxAccessToken={MAPBOX_TOKEN}
        interactiveLayerIds={[clusterLayer.id]}
        onLoad={() => {
          updateMaxClusterSize();
        }}
        onIdle={() => {
          updateMaxClusterSize();
        }}
        // onZoom={() => {
        //   updateMaxClusterSize();
        // }}
        onData={() => {
          // Required, otherwise the first click event won't fire.
          updateMaxClusterSize();
          
        }}
        onMove={evt => {
          props.setViewport(evt.viewState);
          props.setCenterLongitude(props.viewport.longitude);
          props.setCenterLatitude(props.viewport.latitude);
         }}
        ref={mapRef}
        maxZoom={15}
      > 
        
        <Source
          id="postPoints"
          type="geojson"
          data={props.postGeoJSON}
          cluster={true}  
          // clusterMaxZoom={14}
          clusterRadius={25}
        >
          <Layer {...clusterLayer(maxClusterSize)} />
          <Layer {...clusterCountLayer(maxClusterSize)} />
          <Layer {...unclusteredPointLayer} />
        </Source>

        {pointData && (
          <Source 
            id="projectCivilian" 
            type="geojson"
            data={pointData}>
            <Layer {...pointLayer} />
            <Layer {...pointLayerLabel} />
          </Source>
        )}
        
        {props.currentLatitude && (
          <Source 
            id="current-location" 
            type="geojson"
            data={currentLocationData}>
            <Layer {...currentLocationLayer} />
            <Layer {...currentLocationLabel} 
            />
          </Source>
        )}
      </Map>
      {/* {!pointsLoaded &&
      <div className={'loader'}>
        <PuffLoader
          size={250}
          color="#000000"
          speedMultiplier={.1}
        />
      </div>} */}
    </>
  );
};

export default PostMap;